import { Stack, Typography, Button, Divider } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import { Redirect, Link as RouterLink } from 'react-router-dom';

import { environment } from '@env';

import { DashboardStats } from '@/types/dashboard';

import { Building01Icon } from '@/assets/icons/Building01Icon';
import { Mail02Icon } from '@/assets/icons/Mail02Icon';
import { Phone02Icon } from '@/assets/icons/Phone02Icon';
import { Users02Icon } from '@/assets/icons/Users02Icon';
import { PMPRoutes } from '@/config/routes';
import { useAuth } from '@/context/AuthProvider';
import { useOnboardingProgress } from '@/context/OnboardingProgressProvider';
import { usePropertyManager } from '@/context/PropertyManagerProvider';
import { formatPhoneNumber } from '@/utils/phone';
import { getResolvedRoutePath } from '@/utils/router';

import DashboardExpiringLeases from '@/components/dashboard/DashboardExpiringLeases';
import DashboardIssueSummary from '@/components/dashboard/DashboardIssueSummary';
import DashboardSection from '@/components/dashboard/DashboardSection';
import DashboardStatsCard from '@/components/dashboard/DashboardStatsCard';
import LoadingState from '@/components/LoadingState';

const DashboardPage: React.FC = () => {
  const { session } = useAuth();
  const { isFetching, isOnboardingComplete } = useOnboardingProgress();
  const { selectedPropertyLocation } = usePropertyManager();

  const { data: stats, isLoading: isStatsLoading } = useQuery({
    enabled:
      !!session?.access_token && !isFetching && isOnboardingComplete && !!selectedPropertyLocation,
    queryKey: ['GET', 'dashboard', 'stats', session?.access_token, selectedPropertyLocation?.id],
    queryFn: async () => {
      try {
        const response = await axios.get<DashboardStats>(
          `${environment.api}/dashboard/${selectedPropertyLocation?.id}/stats`,
          {
            headers: {
              Authorization: `Bearer ${session?.access_token}`,
            },
          }
        );
        return response.data;
      } catch (error) {
        enqueueSnackbar('Failed to fetch dashboard stats.', { variant: 'error' });
        return null;
      }
    },
  });

  if (isFetching) return <LoadingState />;

  if (!isOnboardingComplete) return <Redirect to={PMPRoutes.onboardingChecklist} />;

  return (
    <Stack spacing={3}>
      {selectedPropertyLocation && (
        <DashboardSection>
          <Stack
            spacing={2}
            direction={{
              xs: 'column',
              sm: 'row',
            }}
            justifyContent="space-between"
            alignItems={{
              sm: 'center',
            }}
          >
            <Stack spacing={1}>
              <Typography variant="subtitle3">{selectedPropertyLocation.name}</Typography>
              <Typography variant="body3">{selectedPropertyLocation.address_text}</Typography>
              <Stack
                direction={{
                  xs: 'column',
                  sm: 'row',
                }}
                spacing={0.5}
              >
                <Typography variant="body3">
                  {selectedPropertyLocation.type === 'SINGLE_FAMILY_HOME'
                    ? 'Single Family Home'
                    : 'Multi Unit'}
                </Typography>

                <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />

                <Typography variant="body3">
                  {selectedPropertyLocation.phone
                    ? formatPhoneNumber(selectedPropertyLocation.phone.value)
                    : 'No phone'}
                </Typography>

                <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />

                <Typography variant="body3" color="text.secondary">
                  {selectedPropertyLocation.email?.value || 'No email'}
                </Typography>
              </Stack>
            </Stack>

            <Button
              variant="outlined"
              color="secondary"
              component={RouterLink}
              size="small"
              to={getResolvedRoutePath(PMPRoutes.propertyLocationView, {
                id: String(selectedPropertyLocation.id),
              })}
            >
              View Location Details
            </Button>
          </Stack>
        </DashboardSection>
      )}
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={3}
        sx={{
          flexWrap: 'wrap',
          '& > *': {
            minWidth: { xs: '100%', sm: 'calc(50% - 12px)', md: 'calc(25% - 18px)' },
          },
        }}
      >
        <DashboardStatsCard
          isLoading={isStatsLoading}
          Icon={<Mail02Icon />}
          stats={[
            {
              label: 'Pending Emails',
              type: 'warning',
              value: stats?.pendingEmailCount || 0,
              valuePrimary: {
                type: 'error',
                value: stats?.emergencyEmailCount || 0,
              },
              valueSecondary: {
                type: 'success',
                value: stats?.completedEmailCount || 0,
              },
            },
            {
              label: 'Emergency Emails',
              type: 'error',
              value: stats?.emergencyEmailCount || 0,
              location: PMPRoutes.inboundMessagesWithTenants,
            },
            {
              label: 'In-Progress Emails',
              type: 'warning',
              value: stats?.pendingEmailCount || 0,
              location: PMPRoutes.inboundMessagesWithTenants,
            },
            {
              label: 'Completed Emails',
              type: 'success',
              value: stats?.completedEmailCount || 0,
              location: PMPRoutes.inboundMessagesWithTenants,
            },
          ]}
        />

        <DashboardStatsCard
          isLoading={isStatsLoading}
          Icon={<Phone02Icon />}
          stats={[
            {
              label: 'Pending SMS',
              type: 'warning',
              value: stats?.pendingSmsCount || 0,
              valuePrimary: {
                type: 'error',
                value: stats?.emergencySmsCount || 0,
              },
              valueSecondary: {
                type: 'success',
                value: stats?.completedSmsCount || 0,
              },
            },
            {
              label: 'Emergency SMS',
              type: 'error',
              value: stats?.emergencySmsCount || 0,
              location: PMPRoutes.inboundMessagesWithTenants,
            },
            {
              label: 'In-Progress SMS',
              type: 'warning',
              value: stats?.pendingSmsCount || 0,
              location: PMPRoutes.inboundMessagesWithTenants,
            },
            {
              label: 'Completed SMS',
              type: 'success',
              value: stats?.completedSmsCount || 0,
              location: PMPRoutes.inboundMessagesWithTenants,
            },
          ]}
        />

        <DashboardStatsCard
          isLoading={isStatsLoading}
          Icon={<Building01Icon />}
          stats={[
            {
              label: 'Total Units',
              type: 'success',
              value: stats?.totalUnits || 0,
            },
          ]}
        />
        <DashboardStatsCard
          isLoading={isStatsLoading}
          Icon={<Users02Icon />}
          stats={[
            {
              label: 'Total Tenants',
              type: 'success',
              value: stats?.totalTenants || 0,
            },
          ]}
        />

        <Stack
          direction={{ xs: 'column', md: 'row' }}
          spacing={3}
          sx={{
            width: '100%',
            '& > *': {
              flex: { md: 1, xs: 'unset' },
              height: '30rem',
            },
          }}
        >
          <DashboardIssueSummary />

          <DashboardExpiringLeases />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default DashboardPage;
